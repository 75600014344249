<template>
  <div class="view--main">
    <v-card class="card--main d-flex flex-column align-center justify-center">
      <v-btn
        class="my-2 start-button"
        color="success"
        x-large
        @click="$router.push({ name: 'signup' })"
      >
        Регистрация
      </v-btn>

      <v-btn
        class="my-2 start-button"
        color="primary"
        x-large
        @click="$router.push({ name: 'signin' })"
      >
        Вход
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  mounted() {
    this.$store.dispatch('stopIdleTracker');
  },
};
</script>

<style lang="sass">
.card--main
  min-height: 300px
.start-button
  min-width: 176px !important
</style>
